



















import Vue from 'vue'
import Component from 'vue-class-component'
import AccountingBlindFilters from "@/modules/accounting/components/AccountingBlindFilters.vue";
import AccountingBlindTable from "@/modules/accounting/components/AccountingBlindTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniAccessModal from "@/components/layout/SygniAccessModal.vue";
import FileUploader from "@/components/FileUploader.vue";
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import { DocumentItem } from '@/modules/accounting/store/types';
import { BTable } from 'bootstrap-vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
// import { AxiosRequestConfig } from 'axios';

@Component({
  components: {AccountingBlindFilters, FileUploader, AccountingBlindTable, SygniModal, BTable, SygniRoundedButton, SygniAccessModal},
  computed: mapState('accounting', {
    documentsTable: (state: any) => state.documentsTable,
    isBusy: (state: any) => state.documentsTableIsBusy,
    perPage: (state: any) => state.documentsTable.perPage,
    documentsUploadProgress: (state: any) => state.documentsUploadProgress,
  })
})
export default class accountingModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf'];
  tempFile: string = "";
  documentsTable!: TableData<DocumentItem>;
  perPageOption?: number = 10;
  isBusy!: boolean;
  showDeleteModal: boolean = false;
  showUploadFilesModal: boolean = false;
  uploadFilesModalItems: Array<any> = [];
  deleteItemId: string = '';
  filtersQueryString: string = '';
  isLoading: boolean = false;
  showAccessModal: boolean = false;

  get perPageValue() {
    return this.perPageOption;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isApprover() {
    if(!this.activeUserData) return false;

    return this.activeUserData.role.workflow.includes('approver');
  }

  async beforeMount() {
    if(this.activeUserData.context.context == 'company' || this.activeUserData.context.context == 'fund' || this.activeUserData.context.context == 'fund-manager') {
      await this.$store.dispatch('accounting/getDictionaries');
    }
  }
}
