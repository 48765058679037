






























































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BTable, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniCheckbox, SygniSquareButton, SygniRectButton },
})
export default class AccountingBlindTable extends SygniTable<any> {

  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  bulkOptionsMarginTop: number = 0;
  refresh: any = null;

  blindItems: Array<any> = [
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "722ca5b2-19af-4de3-b1dd-d616303add96",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "70008600122002721760",
      "fileName": "18_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "5250007313",
      "saleDate": "2022-01-11T00:00:00+00:00",
      "issueDate": "2022-01-11T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 27,
      "status": "processed",
      "created": "2022-05-17T08:00:23+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "\"POCZTA POLSKA SPÓŁKA AKCYJNA\"",
      "additionDate": "2022-05-17T08:02:21+00:00",
      "paymentDate": "2022-01-11T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "61d8ec0a-6711-41be-b599-f5626aa7ca02",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FVS/22/02/5316",
      "fileName": "18 02 2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "5272749631",
      "saleDate": "2022-02-22T00:00:00+00:00",
      "issueDate": "2022-02-23T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 3443.14,
      "status": "processed",
      "created": "2022-05-17T08:00:22+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "GRUPA PRACUJ SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-03-09T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "1af03360-e39f-48e2-acaf-26e078830b8d",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "2022/FVS/014631/BS",
      "fileName": "4_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "8361676510",
      "saleDate": "2022-01-31T00:00:00+00:00",
      "issueDate": "2022-01-01T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 1383.98,
      "status": "processed",
      "created": "2022-05-17T08:00:22+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "BENEFIT SYSTEMS SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:21+00:00",
      "paymentDate": "2022-01-15T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "edit",
        "delete"
      ],
      "id": "e7043b16-2fde-4794-8e89-5fe40dd96f89",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": null,
      "fileName": "12_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": null,
      "saleDate": null,
      "issueDate": null,
      "currency": null,
      "grossAmount": null,
      "status": "failed",
      "created": "2022-05-17T08:00:22+00:00",
      "documentType": null,
      "counterPartyName": null,
      "additionDate": null,
      "paymentDate": null,
      "paymentStatus": null,
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": "Unrecognized language on the document"
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "cb94401b-34d9-47ac-add4-fe0b7d977119",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FZAL/SZ/251/01/2022/1012",
      "fileName": "27_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "5260307956",
      "saleDate": "2022-01-31T00:00:00+00:00",
      "issueDate": "2022-01-31T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 3060,
      "status": "processed",
      "created": "2022-05-17T08:00:22+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "STOWARZYSZENIE KSIĘGOWYCH W POLSCE",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-01-31T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "b727a977-81f7-4345-b91f-cc4bcb4a812c",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FS-1580/2022/OCRE",
      "fileName": "16_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "6770065406",
      "saleDate": "2022-01-22T00:00:00+00:00",
      "issueDate": "2022-01-03T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 369,
      "status": "processed",
      "created": "2022-05-17T08:00:21+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "COMARCH SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-01-17T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "37f8f7b5-d2ec-4089-a4ba-9e3fd1d0c452",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FS-1380/2022/ESKLE",
      "fileName": "15 02 2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "6770065406",
      "saleDate": "2022-02-16T00:00:00+00:00",
      "issueDate": "2022-02-16T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 110,
      "status": "processed",
      "created": "2022-05-17T08:00:21+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "COMARCH SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-02-16T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "cb0ccc5d-168b-4cb0-92ee-b72553d96926",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FS-543/2022/ESKLE",
      "fileName": "14_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "6770065406",
      "saleDate": "2022-01-19T00:00:00+00:00",
      "issueDate": "2022-01-19T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 100,
      "status": "processed",
      "created": "2022-05-17T08:00:21+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "COMARCH SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-01-19T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "21250402-03bd-4995-99bd-798c125dd097",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FS-414/2022/ESKLE",
      "fileName": "13_01_2022_Zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "6770065406",
      "saleDate": "2022-01-14T00:00:00+00:00",
      "issueDate": "2022-01-17T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 100,
      "status": "processed",
      "created": "2022-05-17T08:00:21+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "COMARCH SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-01-17T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    },
    {
      "actions": [
        "send-to-approval",
        "edit",
        "delete"
      ],
      "id": "d3be4258-1125-4e4a-b528-2dc85cdc3a66",
      "legalEntityId": "e23ee515-38eb-4bd9-8913-c99203c6fdcf",
      "documentNumber": "FS-3625/2022/OCRE",
      "fileName": "16 02 2022_zakup.pdf",
      "filePath": "",
      "pdfFilePath": "",
      "taxNumber": "6770065406",
      "saleDate": "2022-02-22T00:00:00+00:00",
      "issueDate": "2022-02-01T00:00:00+00:00",
      "currency": "PLN",
      "grossAmount": 369,
      "status": "processed",
      "created": "2022-05-17T08:00:21+00:00",
      "documentType": "Purchase invoice",
      "counterPartyName": "COMARCH SPÓŁKA AKCYJNA",
      "additionDate": "2022-05-17T08:02:20+00:00",
      "paymentDate": "2022-02-15T00:00:00+00:00",
      "paymentStatus": "unknown",
      "costCenter": null,
      "glAccount": null,
      "accountingId": null,
      "description": null,
      "errorMessage": null
    }
  ];

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    // { key: 'selected', sortable: false, borderless: true, label: ''},
    { key: 'documentNumber', sortable: true, label: 'Doc. No.'},
    { key: 'fileName', class: 'hide', sortable: true, borderless: true, label: 'File Name' },
    { key: 'counterPartyName', sortable: true, borderless: true , label: 'Counterparty Name' },
    { key: 'saleDate', class: 'hide', sortable: true, borderless: true, label: 'Sale Date' },
    { key: 'paymentDate', class: 'hide', sortable: true, borderless: true, label: 'Payment Date' },
    { key: 'grossAmount', class: 'hide', sortable: true, borderless: true, label: 'Gross Amount' },
    { key: 'currency', class: 'hide', sortable: true, label: 'Currency' },
    { key: 'status', sortable: true, label: 'Status' },
    { key: 'actions', sortable: false, label: 'Actions' },
  ];

  onFiltersChange(): void {}

  onSortChange(): void {}

  toggleAllRowsAction() {
    if(this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if(this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.accountingTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.accountingTable as BTable).selectAllRows();
    } else {
      (this.$refs.accountingTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  getDocumentStatusClassName(status: string): string {
    let className = 'primary';
    switch(status) {
      case('uploaded'):
        className = 'primary';
        break;
      case('processing'):
        className = 'primary';
        break;
      case('processed'):
        className = 'success';
        break;
      case('sent-to-approval'):
        className = 'success';
        break;
      case('approved'):
        className = 'primary';
        break;
      case('booked'):
        className = 'primary';
        break;
      case('failed'):
        className = 'danger';
        break;
      case('rejected'):
        className = 'danger';
        break;
      case('rejected-by-operator'):
        className = 'danger';
        break;
      case('canceled'):
        className = 'danger';
        break;
      default:
        className = 'primary';
    }

    return className;
  }

  statusText(status: string): string {
    switch(status) {
      case('approved'): {
        return 'Approved';
      }
      case('processing'): {
        return 'Processing';
      }
      case('uploaded'): {
        return 'Uploaded';
      }
      case('processed'): {
        return 'Processed';
      }
      case('sent-to-approval'): {
        return 'Sent for approval';
      }
      case('rejected'): {
        return 'Rejected'; 
      }
      case('failed'): {
        return 'Failed';
      }
    }
    return status;
  }

  setQuery() {}

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el, index) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }
}

