var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounting-module"
  }, [_c('accounting-blind-filters'), _c('div', {
    staticClass: "container-fluid"
  }, [!_vm.isApprover ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('file-uploader', {
    attrs: {
      "uploadProgress": {
        progress: -1
      },
      "supported-file-formats": _vm.supportedFileFormats,
      "dropAreaText": "Drag and drop to add your new document",
      "infoText": "File format: pdf / max 5mb"
    }
  })], 1)]) : _vm._e(), _c('div', {
    class: ['row', _vm.isApprover ? 'mt-4' : '']
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('accounting-blind-table', {
    ref: "accountingTable",
    attrs: {
      "show-page-number-options": true,
      "per-page": 10
    }
  })], 1)])]), _c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }